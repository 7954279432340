import React from "react"
import { types } from "../index"

const Cards = ({ type = "", bookings = [] }) => {
  const commission = bookings.reduce(
    (partialSum, item) => partialSum + Number(item.serviceFee),
    0
  )
  const earnings = bookings.reduce(
    (partialSum, item) => partialSum + Number(item.total),
    0
  )
  const payout = earnings - commission
  return (
    <div className={"row"}>
      <div className={"col-sm-6 col-md-4 col-xl-3"}>
        <div className="card border text-center">
          <div className="card-body">
            <h3 className="card-title m-0">{bookings.length}</h3>
          </div>
          <div className="card-footer text-muted">Bookings</div>
        </div>
      </div>
      <div className={"col-sm-6 col-md-4 col-xl-3"}>
        <div className="card border text-center">
          <div className="card-body">
            <h3 className="card-title m-0">
              {earnings.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </h3>
          </div>
          <div className="card-footer text-muted">Earnings</div>
        </div>
      </div>
      {type !== types[1] && (
        <div className={"col-sm-6 col-md-4 col-xl-3"}>
          <div className="card border text-center">
            <div className="card-body">
              <h3 className="card-title m-0">
                {commission.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </h3>
            </div>
            <div className="card-footer text-muted">Commission Paid</div>
          </div>
        </div>
      )}
      <div className={"col-sm-6 col-md-4 col-xl-3"}>
        <div className="card border text-center">
          <div className="card-body">
            <h3 className="card-title m-0">
              {payout.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </h3>
          </div>
          <div className="card-footer text-muted">Total Payout</div>
        </div>
      </div>
    </div>
  )
}

export default Cards
