import React, { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { types } from "../index"
import { format } from "date-fns"
import get from "lodash/get"

const defaultHeaders = [
  { label: "ID", key: "id" },
  { label: "Name", key: "name" },
  { label: "Date", key: "date" },
  { label: "Location", key: "location" },
  { label: "Type", key: "type" },
  { label: "Value", key: "price" },
]

const Table = ({ type = "", bookings = [], csvName = "" }) => {
  const [formattedBookings, setFormattedBookings] = useState([])
  const [headers, setHeaders] = useState([])

  useEffect(() => {
    const formattedBookings = bookings.map((booking) => {
      const dateStr = get(booking, "date", null)
      const dateObj = dateStr ? new Date(dateStr) : new Date()
      const date = format(dateObj, "yyyy-MM-dd")
      const serviceFee = booking?.serviceFee || 0
      const total = booking?.total || 0
      const data = {
        id: booking?.id,
        name: booking?.name || "N/A",
        date,
        location: booking?.location || "N/A",
        type: booking?.type || "-",
        price: total.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
      }

      if (type !== types[1]) {
        data.commission = serviceFee.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })
      }

      return data
    })

    setFormattedBookings(formattedBookings)
    if (type === types[1]) {
      setHeaders(defaultHeaders)
    } else {
      setHeaders([
        ...defaultHeaders,
        { label: "Commission", key: "commission" },
      ])
    }
  }, [type])

  return (
    <div className={"my-5"}>
      <CSVLink
        headers={headers}
        data={formattedBookings}
        filename={`worksimply-report-${csvName}.csv`}
        className="btn btn-primary btn-sm ml-auto"
      >
        Download CSV
      </CSVLink>
      <table className="table table-striped table-hover mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Location</th>
            <th>Type</th>
            <th>Value</th>
            {type !== types[1] && <th>Commission</th>}
          </tr>
        </thead>

        <tbody>
          {formattedBookings.map((booking) => {
            return (
              <tr key={booking.id}>
                <td>{get(booking, "name", "N/A")}</td>
                <td>{booking?.date}</td>
                <td>{get(booking, "location", "N/A")}</td>
                <td>{get(booking, "type", "N/A")}</td>
                <td>{booking?.price}</td>
                {type !== types[1] && <td>{booking?.commission}</td>}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table
