import React, { useEffect, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import { useAuth } from "@auth/useAuth"
import { navigate } from "gatsby"
import get from "lodash/get"
import cx from "classnames"
import { format, subMonths } from "date-fns"
import ProgressDots from "../../components/ProgressDots"
import Cards from "./components/_Cards"
import Table from "./components/_Table"

export const types = ["All", "Website", "Worksimply"]

const thisMonth = new Date()

const ReportsPage = () => {
  const {
    makeRequest,
    isAuthenticated,
    hasScope,
    user,
    isWorksimplyAdmin,
    isApiUser,
  } = useAuth() || {}

  const [month, setMonth] = useState(format(thisMonth, "yyyy-MM"))
  const [allBookings, setAllBookings] = useState([])
  const [bookings, setBookings] = useState([])
  const [type, setType] = useState(types[0])

  const [fetchProgress, setFetchProgress] = useState(true)

  useEffect(() => {
    if (!isAuthenticated || !get(user, "id", false)) return
    if (isWorksimplyAdmin && isApiUser) {
      navigate(`/`)
      return
    }
  }, [isAuthenticated, user])

  useEffect(() => {
    setFetchProgress(true)
    makeRequest("so-reports", { month })
      .then((res) => {
        setAllBookings(get(res, "allBookings", []))
        setFetchProgress(false)
      })
      .catch(() => {
        setFetchProgress(false)
      })
  }, [month])

  useEffect(() => {
    if (type === types[0]) {
      setBookings(allBookings)
    } else if (type === types[1]) {
      setBookings(allBookings.filter((item) => !item.isWorksimply))
    } else {
      setBookings(allBookings.filter((item) => item.isWorksimply))
    }
  }, [allBookings, type])

  return (
    <>
      <SEO title="Reports" />
      <Layout title={"Reports"} progress={false}>
        {/*  Page Content */}

        <div className="d-flex align-items-center justify-content-start">
          <p className="m-0 mr-3">Month:</p>
          <select
            name="month"
            id="month"
            className="form-control w-50"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            disabled={fetchProgress}
          >
            <option value={format(new Date(), "yyyy-MM")}>
              {format(new Date(), "MMMM, yyyy")}
            </option>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => (
              <option
                key={i}
                value={format(subMonths(new Date(), i), "yyyy-MM")}
              >
                {format(subMonths(new Date(), i), "MMMM, yyyy")}
              </option>
            ))}
          </select>
          <div className={"position-relative px-5"}>
            <ProgressDots active={fetchProgress} />
          </div>
        </div>

        <div className="btn-group my-5" role="group" aria-label="Type Selector">
          {types.map((t) => (
            <button
              type="button"
              key={t}
              className={cx("btn btn-sm", {
                "btn-success": t === type,
                "btn-light": t !== type,
              })}
              onClick={() => setType(t)}
            >
              {t}
            </button>
          ))}
        </div>

        {!fetchProgress && bookings.length === 0 && (
          <p>No bookings found for "{type}" this month</p>
        )}

        {/*  Page Data */}
        {!fetchProgress && bookings.length > 0 && (
          <Cards type={type} bookings={bookings} />
        )}
        {!fetchProgress && bookings.length > 0 && (
          <Table type={type} csvName={month} bookings={bookings} />
        )}
      </Layout>
    </>
  )
}

export default ReportsPage
